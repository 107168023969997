html {
  background-color: #f2ead0 !important;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.picture-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.picture-container:hover .caption {
  opacity: 1;
}

.modal {
  position: fixed;
}
